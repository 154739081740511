table {
  border-collapse: collapse;
  //border: 1px solid rgb(204,204,204);
  border-radius: 4px;
}

thead td {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-top: 4px;
  padding-bottom: 4px;
}

tbody tr:hover {
  background: rgba(38, 132, 255, 0.08);
}

td {
  border: 1px solid rgb(204,204,204);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

tr:last-child {
 border-right: 0px;
}

pre {
  margin: 0;
}